import React, { useContext } from "react"
import { AppContext } from "../contexts/AppContext"
import ContentRegister from "../components/ContentRegister"

const RegisterPage = () => {
  let { selectedCountry } = useContext(AppContext)
  return <>{selectedCountry && <ContentRegister />}</>
}

export default RegisterPage
