import React, { useState, useContext } from "react"
import styled from "styled-components"
import axios from "axios"
import auth from "../../auth/auth"
import { navigate } from "gatsby"
import { NotificationManager } from "react-notifications"
import { AppContext } from "../../contexts/AppContext"
import { capitalize } from "lodash"
import WebsiteLogo from "../../images/SA_logo.png"
import RegisterBackgroundImg from "../../images/asset-2.png"
import { Icon } from "rsuite"
import {
  COLOR_DEFAULT_TEXT_DARK,
  COLOR_SEARCH_INPUT_TEXT,
  COLOR_DEFAULT_TEXT_DISABLED,
} from "../../constants/colors"
import {
  PROP_FONT_SECONDARY,
  ROLE_ARTIST,
  ROLE_STATIONADMIN,
  ROLE_LISTENER,
} from "../../constants/props"
import {
  MOBILE,
  LARGE,
  MEDIUM,
  TAB,
  LANDSCAPE,
} from "../../constants/breakpoints"
import useButtonLoader from "../../hooks/useButtonLoader"

const SignUpMainContent = styled.div`
  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
  @media (max-width: ${MOBILE.maxWidth}px) {
    background-image: none;
    width: ${MOBILE.publicPages.signup.width}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.publicPages.signup.width}vw;
    background-image: none;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.publicPages.signup.width}px;
    background-image: none;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.publicPages.signup.width}px;
    background-image: url(${props => props.image});
    background-size: 22%;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    background-image: url(${props => props.image});
    width: ${LARGE.publicPages.signup.width}px;
    background-size: 22%;
  }
`
const SignUpParent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: 10px auto;
  height: 100%;

  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.publicPages.signup.width}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.publicPages.signup.width}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.publicPages.signup.width}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.publicPages.signup.width}px;
  }
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin: 10px auto;
    width: ${MOBILE.publicPages.signup.formWidth}px;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    margin: 10px auto;
    width: ${LANDSCAPE.publicPages.signup.formWidth}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin: 10px 50px;
    width: ${TAB.publicPages.signup.formWidth}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.publicPages.signup.formWidth}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.publicPages.signup.formWidth}px;
  }
`
const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  @media (max-width: ${MOBILE.maxWidth}px) {
    display: none;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    display: none;
  }
  @media (min-widt h: ${TAB.minWidth}px) {
    display: none;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    display: block;
  }
`
const WebLogoImg = styled.img`
  width: 150px;
  margin-top: 60px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    display: none;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    display: none;
  }
  @media (min-width: ${TAB.minWidth}px) {
    display: none;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    display: block;
  }
`
const SignUpSubHeading = styled.h1`
  font-size: 40px;
  margin-top: 20px;
`
const FormHeading = styled.h3`
  padding-top: 1.42vh;
  display: flex;
  justify-content: center;
  color: #eeeeee;
  font-weight: normal;
  margin: 0;
  ::selection {
    color: none;
    background: none;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 10px;
    font-size: ${MOBILE.publicPages.signup.font.heading}px;
    margin-bottom: ${MOBILE.publicPages.HeadingBottomMargin}px;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    margin-top: 10px;
    font-size: ${LANDSCAPE.publicPages.signup.font.heading}px;
    margin-bottom: ${MOBILE.publicPages.HeadingBottomMargin}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-top: 10px;
    font-size: ${TAB.publicPages.signup.font.heading}px;
    margin-bottom: ${MOBILE.publicPages.HeadingBottomMargin}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: ${MEDIUM.publicPages.signup.font.heading}px;
    margin-bottom: ${MOBILE.publicPages.HeadingBottomMargin}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    font-size: ${LARGE.publicPages.signup.font.heading}px;
    margin-bottom: ${MOBILE.publicPages.HeadingBottomMargin}px;
  }
`
const FormInput = styled.input`
  height: 50px;
  width: 100%;
  border: 1px solid #989dad;
  border-radius: 10px;
  outline: none;
  box-shadow: none;
  color: #fff;
  margin: 20px 0px;
  background: none;
  padding: 10px;
  font-size: 18px;
  ::placeholder {
    color: ${COLOR_SEARCH_INPUT_TEXT};
  }
`

const FormSubmitButton = styled.button`
  width: 50%;
  background-color: #ffffff;
  height: auto;
  font-size: 18px;
  border-radius: 5px;
  padding: 7px;
  align-self: flex-end;
  color: ${COLOR_DEFAULT_TEXT_DARK};
  ::selection {
    color: none;
    background: none;
    border: none;
  }
  :disabled {
    color: ${COLOR_DEFAULT_TEXT_DISABLED};
  }
`

const ErrorMessage = styled.p`
  display: block;
  opacity: ${props => (props.hasData ? "1" : "0")};
  padding: 1rem 2.08vw;
  color: #9e0101;
  background: #fff;
  font-size: 1rem;
  border-left: 4px solid #9e0101;
  transition: opacity 500ms linear;
  padding: 5px 15px;
  @media (min-width: ${TAB.minWidth}px) {
    padding: 5px 30px;
  }
  margin-top: 10px;
`
const FormNameEntry = styled.div`
  display: flex;
  justify-content: space-between;
`

const FirstNameWrapper = styled.div`
  margin: 20px 10px 0px 0px;
  flex: 1;
`
const LastNameWrapper = styled.div`
  margin: 20px 0px 0px 10px;
  flex: 1;
`

const PasswordWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  position: relative;
`

const PasswordHint = styled.span`
  margin-bottom: 10px;
`

const SwitchPanel = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
  font-family: ${PROP_FONT_SECONDARY};
  font-size: 16px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 24px;
  }
`
const PanelTab = styled.div`
  flex: 1;
  cursor: pointer;
  border-bottom: ${props =>
    props.isActive ? "4px solid white" : "1px solid white"};
  opacity: ${props => (props.isActive ? "1" : "0.5")};
`
const EyeButton = styled(Icon)`
  font-size: ${MOBILE.box.iconSize}px !important;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 38%;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: ${LANDSCAPE.box.iconSize}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.iconSize}px;
  }
`

const ContentRegister = props => {
  let { device, userState, setUserState } = useContext(AppContext)
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    role: ROLE_LISTENER,
  })
  const [showPassword, setShowPassword] = useState(false)

  const [loaderElement, setLoader] = useButtonLoader("Next", "Processing...")

  const [registered, setRegistered] = useState({
    registered: false,
    confirmed: false,
  })
  const [errorData, setErrorData] = useState("")
  const [displayError, setDisplayError] = useState(false)

  const showError = error => {
    setLoader(false)
    setErrorData(error)
    setDisplayError(true)
    setTimeout(() => {
      hideError()
    }, 3000)
  }

  const hideError = () => {
    setDisplayError(false)
  }

  const validateAndSend = e => {
    setLoader(true)
    e && e.preventDefault()
    setErrorData("")
    if (!formData.firstname) {
      showError("Please add first name")
      return
    }
    if (!formData.lastname) {
      showError("Please add last name")
      return
    }

    if (
      (formData.role === ROLE_STATIONADMIN || formData.role === ROLE_ARTIST) &&
      !formData.displayname
    ) {
      showError("Please add display name")
      return
    }

    if (
      (formData.role === ROLE_STATIONADMIN || formData.role === ROLE_ARTIST) &&
      !formData.contactnumber
    ) {
      showError("Please add Contact Number")
      return
    }
    if (formData.contactnumber) {
      const contactNumberCheck = '^[\+]?[0-9]*$'
      if (!formData.contactnumber.match(contactNumberCheck)){
        showError("Contact can only contain number but can optionally start with + sign")
        return
      }
    }
    if (!formData.email) {
      showError("Please add email")
      return
    }
    if (formData.email) {
      const emailCheck = "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}"
      if (!formData.email.match(emailCheck)){
        showError("Please enter valid email")
        return
      }
    }
    if (!formData.password) {
      showError("Please add password")
      return
    }
    if (formData.password) {
      const passwordLetterCheck = "^(?=.*[a-z])(?=.*[A-Z])"
      const passwordLengthCheck = "^(?=.{8,})"
      const passwordNumberCheck = "^(?=.*[0-9])"
      if (!formData.password.match(passwordLengthCheck)){
        showError("Password must have at least 8 characters")
        return
      }
      if (!formData.password.match(passwordLetterCheck)){
        showError("Password must have one uppercase and lowercase")
        return
      }
      if (!formData.password.match(passwordNumberCheck)){
        showError("Password must have one number")
        return
      }
    }

    axios
      .post(`${process.env.STRAPI_SERVER_URL}/auth/local/register`, {
        firstname: formData.firstname,
        lastname: formData.lastname,
        displayname: formData.displayname,
        contactnumber: formData.contactnumber,
        username:
          capitalize(formData.firstname) +
          capitalize(formData.lastname) +
          Math.floor(Math.random() * 90000) +
          10000,
        email: formData.email,
        password: formData.password,
        role: formData.role,
      })
      .then(response => {
        // Handle success. Dump to localstorage
        setRegistered({
          registered: true,
          confirmed: response.data.user.confirmed,
        })
        if (response.data.jwt) {
          // Set the user's credentials
          const user = response.data.user
          if (user) {
            auth.setUserInfo(response.data.user, true)
            auth.setToken(response.data.jwt, true)
            setUserState({
              ...userState,
              isLoggedIn: user.confirmed ? true : false,
              confirmed: user.confirmed,
              blocked: user.blocked,
              id: user.id,
              firstname: user.firstname,
              lastname: user.lastname,
              username: user.username,
              email: user.email,
              provider: user.provider,
            })
            NotificationManager.success("Registration successfully completed!")
            setLoader(false)
          } else {
            setUserState({
              isLoggedIn: false,
              requestSignin: false,
              // User Data From Response
              confirmed: null,
              blocked: null,
              id: null,
              firstname: null,
              lastname: null,
              username: null,
              email: null,
              provider: null,
            })
            setLoader(false)
            NotificationManager.error("Registration failed!")
          }
          navigate("/welcomeuser")
        }
      })
      .catch(error => {
        setLoader(false)
        NotificationManager.error("Registration failed!")
        // Handle error.
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          showError(error.response.data.message[0].messages[0].message)
        }
      })
  }

  return (
    <SignUpMainContent image={RegisterBackgroundImg}>
      <FormHeading>Create your stream africa account</FormHeading>
      <SignUpParent>
        <Form>
          <SwitchPanel ismobile={device === "mobile"}>
            <PanelTab
              onClick={() => setFormData({ role: ROLE_LISTENER })}
              isActive={formData.role === ROLE_LISTENER}
            >
              Listener
            </PanelTab>
            <PanelTab
              onClick={() => setFormData({ role: ROLE_STATIONADMIN })}
              isActive={formData.role === ROLE_STATIONADMIN}
            >
              Station
            </PanelTab>
           {/* <PanelTab
              onClick={() => setFormData({ role: ROLE_ARTIST })}
              isActive={formData.role === ROLE_ARTIST}
            >
              Artist
           </PanelTab> */}
          </SwitchPanel>
          <FormNameEntry>
            <FirstNameWrapper>
              <FormInput
                type="text"
                placeholder="First name"
                onChange={e =>
                  setFormData({ ...formData, firstname: e.target.value })
                }
                style={{
                  margin: "0px",
                  width: "100%",
                }}
                onKeyUp={event => event.keyCode === 13 && validateAndSend()}
              />
            </FirstNameWrapper>
            <LastNameWrapper>
              <FormInput
                type="text"
                placeholder="Last name"
                onChange={e =>
                  setFormData({ ...formData, lastname: e.target.value })
                }
                style={{
                  margin: "0px",
                  width: "100%",
                }}
                onKeyUp={event => event.keyCode === 13 && validateAndSend()}
              />
            </LastNameWrapper>
          </FormNameEntry>
          {(formData.role === ROLE_STATIONADMIN ||
            formData.role === ROLE_ARTIST) && (
              <FormNameEntry>
                <FirstNameWrapper>
                  <FormInput
                    type="text"
                    placeholder={
                      formData.role === ROLE_STATIONADMIN
                        ? "Station Display Name"
                        : "Artist Display Name"
                    }
                    onChange={e =>
                      setFormData({ ...formData, displayname: e.target.value })
                    }
                    style={{
                      margin: "0px",
                      width: "100%",
                    }}
                    onKeyUp={event => event.keyCode === 13 && validateAndSend()}
                  />
                </FirstNameWrapper>
                <LastNameWrapper>
                  <FormInput
                    type="text"
                    placeholder="Contact number"
                    onChange={e =>
                      setFormData({ ...formData, contactnumber: e.target.value })
                    }
                    style={{
                      margin: "0px",
                      width: "100%",
                    }}
                    onKeyUp={event => event.keyCode === 13 && validateAndSend()}
                  />
                </LastNameWrapper>
              </FormNameEntry>
            )}

          <FormInput
            type="email"
            placeholder="Email"
            onChange={e => setFormData({ ...formData, email: e.target.value })}
            onKeyUp={event => event.keyCode === 13 && validateAndSend()}
          />

          <PasswordWrapper>
            <FormInput
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              onChange={e =>
                setFormData({ ...formData, password: e.target.value })
              }
              style={{
                margin: "0px",
                width: "100%",
              }}
              onKeyUp={event => event.keyCode === 13 && validateAndSend()}
            />
            <EyeButton
              icon={showPassword ? "eye-slash" : "eye"}
              onClick={() => {
                setShowPassword(!showPassword)
              }}
            />
          </PasswordWrapper>
          <PasswordHint>
            Min. 8 characters, 1 upper, 1 lower, 1 number
          </PasswordHint>
          <FormSubmitButton
            ref={loaderElement}
            type="button"
            disabled={registered.registered}
            onSubmit={validateAndSend}
            onClick={validateAndSend}
          >
            Next
          </FormSubmitButton>
          <ErrorMessage hasData={displayError ? true : false}>
            {errorData}
          </ErrorMessage>
        </Form>
        <RightContent>
          <WebLogoImg
            src={WebsiteLogo}
            alt="WebsiteLogo"
          />
          <SignUpSubHeading>
            Discover <br /> African Music
          </SignUpSubHeading>
        </RightContent>
      </SignUpParent>
    </SignUpMainContent>
  )
}

export default ContentRegister
